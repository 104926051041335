.mat-checkbox {
    padding-right: 10px;
}

.mat-checkbox .mat-checkbox-layout {
    white-space: normal !important;
}

.mat-form-field {
    width: 100%;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: unset !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    border-top: 0px;
    padding: 0px;
}

.mat-form-field-outline {
    top: 0px !important;
}

.mat-form-field-wrapper {
    padding: 0px !important;
    margin: 0px !important;
}

.mat-form-field-flex {
    margin: 0px !important;
    align-items: center !important;
    justify-content: center !important;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
    top: unset !important;
}

.mat-form-field.mat-form-field-has-label {
    margin-top: 18px;
}

.mat-form-field-label-wrapper {
    top: -18px !important;
    left: -12px !important;
}

.mat-form-field-label {
    font-size: 18px !important;
}

input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([readonly]),
.mat-select {
    background-color: transparent !important;
    height: 30px !important;
    outline: none;
}

input.mat-input-element {
    margin-top: unset !important;
}

textarea.mat-input-element {
    margin: unset !important;
    padding: unset !important;
}

.mat-select {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: unset !important;
}

.mat-input-element[type=date]::after {
    content: '' !important;
}

/* color picker styling start */
.color-picker-wrapper {
    display: flex;
    align-items: center;
}

.color-container {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    border: 1px solid black;
    cursor: pointer;
}
/* color picker styling end */

.fa.primary {
    color: var(--primaryColor);
}
.table-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-container {
    width: 100%;
    overflow-x: auto;
}

.count {
    margin-bottom: 0px !important;
    font-weight: 450;
    padding: 10px 0px;
    text-align: end;
}

/* if you don't provide height class then default will be 400px */
.fixedHeight {
    max-height: 400px;
    overflow: auto;
    clear: both;
}

/* fixed height classes for diff height */
.fixedHeight.height500 {
    max-height: 500px !important;
}

.fixedHeight.height600 {
    max-height: 600px !important;
}

.fixedHeight.height700 {
    max-height: 700px !important;
}

.fixedHeight.height800 {
    max-height: 800px !important;
}

table {
    width: 100%;
}

tbody tr:hover {
    box-shadow: 0 0 10px #999;
}

tr:hover td {
    border-top: none;
    border-bottom: none;
}

table .id-col {
    font-weight: 500 !important;
    color: var(--primaryColor) !important;
    cursor: pointer;
}

.mat-header-cell.cdk-header-cell {
    font-size: 14px;
    color: #000;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
    padding: 0px 5px !important;
}


/* center align header and cell using center or number class start */
/* for non sortable table header */
table tr th:first-child,
table tr td.center,
table tr td.number {
    text-align: center !important;
}

/* center 1st column td */
table tr td:first-child {
    text-align: center !important;
}

/* for sorted table headers */
table tr th:first-child .mat-sort-header-container,
table tr th.center .mat-sort-header-container,
table tr th.number .mat-sort-header-container {
    justify-content: center !important;
}
/* center align end */


/* left align header and cell using center or number class start */
table tr th.left,
table tr td.left {
    text-align: left !important;
}

table tr th.left .mat-sort-header-container {
    justify-content: start !important;
}
/* left align end */


/* right align header and cell using center or number class start */
table tr th.right,
table tr td.right {
    text-align: right !important;
}

table tr th.right .mat-sort-header-container {
    justify-content: end !important;
}
/* right align end */


mat-row,
[mat-row] {
    background: none;
}

.mat-table-sticky {
    background: white;
}

/* Milti row css ----START-----*/
.expandedElementContent {
    text-align: justify;
}

.expandedElementTitle {
    color: var(--primaryTextColor);
    font-size: 15px;
    text-align: start;
}

.expandRowIcon {
    transform: scale(1.5);
    height: 24px !important;
    width: 24px !important;
}

tr.example-detail-row {
    height: 0;
}

tr.example-element-row:not(.example-expanded-row):active {
    background: #efefef;
}

.example-element-row td {
    border-bottom-width: 0;
}

.example-element-detail {
    overflow: hidden;
    display: flex;
}

.example-element-description {
    padding: 16px;
}

.refreshIcon {
    padding-right: 10px;
}

.expandedElementTitle {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 18px !important;
}

.showMoreIcon {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
/* Milti row css ----END-----*/

.mat-dialog-container {
    padding: 0px !important;
}

.mat-dialog-content {
    padding: 16px !important;
    margin: 0px !important;
}

.mat-dialog-title {
    padding: 10px 16px !important;
    margin: 0px !important;
    background-color: #81b741;
    color: white;
}

.mat-dialog-actions {
    padding: 10px 16px !important;
    margin: 0px !important;
    min-height: unset !important;
    justify-content: center;
    /* border-top: 1px solid grey !important; */
}

.page-dimensions {
    height: 100vh;
    width: 100vw;
}

.icon-move {
    cursor: move;
}